import {EFocusSessionsTypes} from 'models';
import {all, put, select, takeLatest} from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import moment from 'moment-timezone';

import {SessionsService} from 'services/sessionsService';
import {IUser, IUserFavourites} from '../../../models/auth/types';
import {
    selectCalendarFocusSessions,
    selectEditingSession,
    selectFocusSession,
    selectFocusSessions,
    selectFocusSessionsFavourites,
} from '../../selectors/focusSessions';
import {
    IFocusSessions,
    ISession,
    IRatings,
    IFocusSessionParticipant,
} from 'models/focusSessions/types';
import {selectAuthUserId} from '../../selectors';
import {AxiosResponse} from 'axios';
import {
    getFocusSessionsByDateRangeSaga,
    setMyFocusSessionsByDateAction,
    wsFocusSessionUpdatedAction,
    wsFocusSessionDeletedAction,
    getFocusSessionsFavouritesSaga,
    setFocusSessionsFavouritesAction,
    setFocusSessionAction2,
    setCalendarFocusSessionAction,
} from '../../actions/focusSessions';
import {
    getFocusSessionsLeaderBoardSaga,
    getTodayFocusSessionsByDateSaga,
    getFocusSessionsMeSaga,
    patchFocusSessionsMyFocusSaga,
    setFocusSessionsByDateAction,
    setFocusSessionsLeaderBoardAction,
    setFocusSessionsMeAction,
    postBookFocusSessionSaga,
    patchBookedFocusSessionSaga,
    deleteFocusSessionSaga,
    setFocusSessionsMePastAction,
    joinFocusSessionSaga,
    getFocusSessionSaga,
    setFocusSessionAction,
    postFocusSessionUserRatingSaga,
    postAddUserToFavouritesListSaga,
    postFocusSessionReportUserSaga,
    postFocusSessionLeaveSaga,
    getMyFocusSessionsByDateActionSaga,
} from '../../actions/focusSessions';
import {
    setFocusSessionsStatsAction,
    setFocusSessionsMyFocusAction,
    getFocusSessionsMyFocusAction,
    getFocusSessionsStatsAction,
    selectFocusSessionsMeta,
    setAuthRemainingFreeSessionsAction,
} from 'store';
import {
    selectAuthUserTimezone,
    selectAuthUserFavourites,
} from '../../selectors/authUser';
import i18n from 'locales/i18n';
import {AuthService} from 'services';

function* handleFocusSessionsMyFocus({
    payload,
}: ReturnType<typeof getFocusSessionsMyFocusAction>) {
    try {
        // ** Get Current Focus
        const {data: myFocus} = yield SessionsService.getMyFocus();

        if (myFocus) {
            yield put(setFocusSessionsMyFocusAction(myFocus?.my_focus));
            payload?.callbackOnSuccess && payload?.callbackOnSuccess();
        }
    } catch (error: any) {
        console.log({handleFocusSessionsMyFocus: error});
        payload.callbackOnError && payload.callbackOnError();
        Sentry.captureException(error);
    }
}

function* handleFocusSessionsStats({
    payload,
}: ReturnType<typeof getFocusSessionsStatsAction>) {
    try {
        // ** Get Statistics
        const {data: myStats} = yield SessionsService.getMyStatistics();

        if (myStats) {
            yield put(setFocusSessionsStatsAction(myStats?.stats));
            payload?.callbackOnSuccess && payload?.callbackOnSuccess();
        }
    } catch (error: any) {
        console.log({error});
        Sentry.captureException(error);
    }
}

function* handleGetFocusSessionsLeaderBoard({
    payload,
}: ReturnType<typeof getFocusSessionsLeaderBoardSaga>) {
    try {
        const {data: leaderboard} = yield SessionsService.getLeaderBoard();

        if (leaderboard) {
            yield put(setFocusSessionsLeaderBoardAction(leaderboard));
            payload?.callbackOnSuccess && payload?.callbackOnSuccess();
        }
    } catch (error: any) {
        payload?.callbackOnError && payload?.callbackOnError();
        console.log({handleGetFocusSessionsLeaderBoard: error});
        Sentry.captureException(error);
    }
}

function* handleFocusSessionsMe({
    payload,
}: ReturnType<typeof getFocusSessionsMeSaga>) {
    try {
        const sessionsMeta: {
            meta: {
                total: number;
                next?: string;
                previous?: string;
                per_page: number;
                page: number;
            };
        } = yield select(selectFocusSessionsMeta);
        const limit = 50;
        const offset: number =
            !sessionsMeta?.meta?.page || sessionsMeta?.meta?.page <= 0
                ? 0
                : sessionsMeta?.meta?.page + 1;
        const {data}: {data: any} = yield SessionsService.getFocusSessionsMe(
            limit,
            offset,
        );

        if (data) {
            yield put(
                setFocusSessionsMeAction({
                    sessions: {
                        meta: {
                            total: data.count,
                            next: data.next,
                            per_page: data?.results?.length,
                            previous: data.previous,
                            page: offset,
                        },
                        results: data.results,
                    },
                    reset: true,
                }),
            );
            payload.callbackOnSuccess && payload.callbackOnSuccess();
        }
    } catch (error: any) {
        console.log({error});
        Sentry.captureException(error);
    }
}

function* handleFocusSessionsFavourites({
    payload,
}: ReturnType<typeof getFocusSessionsFavouritesSaga>) {
    try {
        const {data} = yield SessionsService.getFocusSessionsFavourites(50, 0);

        if (data) {
            yield put(
                setFocusSessionsFavouritesAction({
                    sessions: {
                        meta: {
                            total: data.count,
                            next: data.next,
                            per_page: data?.results?.length,
                            previous: data.previous,
                            page: 50,
                        },
                        results: data.results,
                    },
                    reset: true,
                }),
            );
            payload.callbackOnSuccess && payload.callbackOnSuccess();
        }
    } catch (error) {
        console.log({error});
        Sentry.captureException(error);
    }
}

function* handleFocusSessionsByDate({
    payload,
}: ReturnType<typeof getTodayFocusSessionsByDateSaga>) {
    try {
        const {data: focusSessionsByDate} =
            yield SessionsService.getFocusSessionsByDate({
                date: payload.date,
                filter:
                    payload.filterBy === 'Favourites'
                        ? 'from_favourites'
                        : payload.filterBy === 'My Sessions'
                        ? 'my_sessions'
                        : undefined,
                duration: payload.duration,
                groups: payload.groups,
            });

        if (focusSessionsByDate) {
            yield put(
                setFocusSessionsByDateAction({
                    calendar: focusSessionsByDate,
                }),
            );
            payload.callbackOnSuccess && payload.callbackOnSuccess();
        }
    } catch (error: any) {
        payload.callbackOnError && payload.callbackOnError();
        console.log({error});
        Sentry.captureException(error);
    }
}

function* handlePatchMyFocusSessions({
    payload,
}: ReturnType<typeof patchFocusSessionsMyFocusSaga>) {
    try {
        const {data} = yield SessionsService.patchMyFocus(payload?.myFocus);

        if (data) {
            yield put(setFocusSessionsMyFocusAction(data?.my_focus));
            payload?.callbackOnSuccess && payload?.callbackOnSuccess();
        }
    } catch (error: any) {
        console.log({error});
        Sentry.captureException(error);
    }
}

function* handleBookFocusSession({
    payload,
}: ReturnType<typeof postBookFocusSessionSaga>) {
    try {
        const authUserTimezone: string = yield select(selectAuthUserTimezone) ||
            Intl.DateTimeFormat().resolvedOptions().timeZone;

        let data;

        const {data: createdSession} = yield SessionsService.bookFocusSession({
            data: {
                duration: payload.duration,
                name: payload.name,
                start_at: payload.start_at,
                notes: payload.notes || '',
                title: payload.title,
                colour: payload.colour,
                user_group_id: payload?.groups ? payload.groups : undefined,
            },
        });
        data = createdSession;

        if (payload.sessionRepeat) {
            let endOn: string = '';
            if (payload.sessionEndOn) {
                const time = moment.tz(payload.sessionEndOn, authUserTimezone);

                endOn = time.format();
            }
            yield SessionsService.postFocusSessionsRepeat({
                endsAfterOccurrences: payload.sessionEndAfter
                    ? parseFloat(payload.sessionEndAfter)
                    : null,
                endsAfter: endOn || null,
                endsNever: payload.sessionEndNever,
                parentSession: data.id,
                repeatFrequency: payload.sessionRepeat,
            });
        }

        if (data) {
            const selectCalendarSessions: ISession[] = yield select(
                selectCalendarFocusSessions,
            );

            yield put(
                setFocusSessionsMeAction({
                    sessions: {
                        results: [data],
                    },
                    reset: false,
                }),
            );
            yield put(
                setFocusSessionsByDateAction({
                    calendar: selectCalendarSessions
                        ? selectCalendarSessions.concat([data])
                        : [data],
                }),
            );

            const {data: userData} = yield AuthService.handleGetAuthUser();
            yield put(
                setAuthRemainingFreeSessionsAction(
                    userData.remaining_free_sessions_this_week,
                ),
            );

            payload.callbackOnSuccess && payload.callbackOnSuccess();
        }
    } catch (error: any) {
        console.log({error});
        const quotaExceeded: string = yield i18n.t('common.quotaExceeded');
        payload.callbackOnError &&
            payload.callbackOnError({
                message:
                    error?.response.data.details ||
                    error.response.data.start_at ||
                    error?.response.data.detail,
                title: error?.response.data.detail ? quotaExceeded : '',
                type: error?.response.data.detail ? 'warn' : 'error',
            });
        Sentry.captureException(error);
    }
}

function* handlePatchBookedFocusSession({
    payload,
}: ReturnType<typeof patchBookedFocusSessionSaga>) {
    try {
        const editingSession: ISession = yield select(selectEditingSession);
        const authUserTimezone: string = yield select(selectAuthUserTimezone) ||
            Intl.DateTimeFormat().resolvedOptions().timeZone;
        const time = moment.tz(new Date(payload.start_at), authUserTimezone);
        const startDate = time.format();

        const {data} = yield SessionsService.patchBookedFocusSession({
            data: {
                duration: payload.duration,
                id: payload.id,
                name: payload.name || '',
                title: payload.title || '',
                start_at: startDate,
                update_type: payload.updateType,
                notes: payload.notes || '',
                colour: payload.colour,
                user_group_id: payload?.groups ? payload.groups : null,
            },
        });

        if (payload?.sessionRepeat && editingSession?.repeat_config?.id) {
            // * Only Call the patch endpoint for repeat if one of the values has changed.
            const endsAfter = moment(payload.sessionEndOn).diff(
                editingSession.repeat_config.ends_after,
                'days',
            );
            const endsAfterOccurrences =
                editingSession.repeat_config.ends_after_occurrences &&
                editingSession.repeat_config.ends_after_occurrences !==
                    parseFloat(payload?.sessionEndAfter || '0');
            const endsNever =
                editingSession.repeat_config.ends_never !==
                payload.sessionEndNever;

            if (endsAfter > 0 || endsAfterOccurrences || endsNever) {
                let endOn: string = '';
                if (payload.sessionEndOn) {
                    const time = moment.tz(
                        payload.sessionEndOn,
                        authUserTimezone,
                    );
                    endOn = time.format();
                }
                yield SessionsService.patchFocusSessionsRepeat({
                    endsAfterOccurrences: payload?.sessionEndAfter
                        ? parseFloat(payload.sessionEndAfter)
                        : null,
                    endsAfter: endOn || null,
                    endsNever: !!payload?.sessionEndNever,
                    parentSession: data.id,
                    repeatFrequency: payload.sessionRepeat,
                    focusSessionId: editingSession.repeat_config?.id,
                });
            }
        } else if (payload.sessionRepeat) {
            let endOn: string = '';

            if (payload.sessionEndOn) {
                const time = moment.tz(payload.sessionEndOn, authUserTimezone);
                endOn = time.format();
            }

            yield SessionsService.postFocusSessionsRepeat({
                endsAfterOccurrences: payload.sessionEndAfter
                    ? parseFloat(payload.sessionEndAfter)
                    : null,
                endsAfter: endOn || null,
                endsNever: !!payload?.sessionEndNever,
                parentSession: data.id,
                repeatFrequency: payload.sessionRepeat,
            });
        }

        if (data) {
            const sessions: IFocusSessions = yield select(selectFocusSessions);
            const selectCalendarSessions: ISession[] = yield select(
                selectCalendarFocusSessions,
            );
            const findEditedSession = sessions.results.filter(
                (session: ISession) => session.id !== payload.id,
            );

            const newSessions = (findEditedSession || []).concat([data]);
            const findCalendarItem = selectCalendarSessions?.filter(
                el => el.id !== payload.id,
            );
            const newCalendarSessions = findCalendarItem.concat([data]);

            yield put(
                setFocusSessionsMeAction({
                    sessions: {
                        results: newSessions,
                    },
                    reset: true,
                }),
            );
            yield put(
                setFocusSessionsByDateAction({
                    calendar: newCalendarSessions,
                }),
            );
            payload.callbackOnSuccess && payload.callbackOnSuccess();
        }
    } catch (error: any) {
        console.log({error});
        const e: string = yield i18n.t('common.anErrorHasOccurred');
        payload.callbackOnError &&
            payload.callbackOnError({
                message: error?.response?.data?.detail || e,
            });
        Sentry.captureException(error);
    }
}

function* handleFocusSessionsMePast({
    payload,
}: ReturnType<typeof getFocusSessionsMeSaga>) {
    try {
        const sessionsMeta: {
            meta: {
                total: number;
                next?: string;
                previous?: string;
                per_page: number;
                page: number;
            };
        } = yield select(selectFocusSessionsMeta);
        const limit = 50;
        const offset: number =
            !sessionsMeta?.meta?.page || sessionsMeta?.meta?.page <= 0
                ? 0
                : sessionsMeta?.meta?.page + 1;
        const {data}: {data: any} =
            yield SessionsService.getFocusSessionsMePast({
                limit,
                offset,
            });

        if (data) {
            yield put(
                setFocusSessionsMePastAction({
                    sessions: {
                        meta: {
                            total: data.count,
                            next: data.next,
                            per_page: data?.results?.length,
                            previous: data.previous,
                            page: offset,
                        },
                        results: data.results,
                    },
                    reset: true,
                }),
            );
            payload.callbackOnSuccess && payload.callbackOnSuccess();
        }
    } catch (error: any) {
        console.log({error});
        Sentry.captureException(error);
    }
}

function* handleFocusSession({
    payload,
}: ReturnType<typeof getFocusSessionSaga>) {
    try {
        if (!payload.sessionId) {
            const e: string = yield i18n.t('common.anErrorHasOccurred');
            payload.callbackOnError &&
                payload.callbackOnError({
                    message: e,
                });
            throw new Error(e);
        }

        const {data}: {data: ISession} = yield SessionsService.getFocusSession({
            focusSessionId: payload.sessionId,
        });

        let repeatSession;
        if (data.focus_session_repeat_parent || data.repeat_config?.id) {
            const {data: sessionRepeat} =
                yield SessionsService.getFocusSessionsRepeat({
                    focusSessionId: data.focus_session_repeat_parent
                        ? data.focus_session_repeat_parent
                        : data.repeat_config?.id
                        ? data.repeat_config?.id
                        : 0,
                });

            if (sessionRepeat) repeatSession = sessionRepeat;
        }

        if (data) {
            payload.callbackOnSuccess &&
                payload.callbackOnSuccess(repeatSession, data);
            yield put(
                setFocusSessionAction({
                    session: {...data, repeat_config: repeatSession},
                }),
            );
        }
    } catch (error: any) {
        console.log({error});
        const e: string = yield i18n.t('common.anErrorHasOccurred');
        payload.callbackOnError &&
            payload.callbackOnError({
                message: error?.response?.data?.detail || e,
            });
        Sentry.captureException(error);
    }
}

function* handleFocusSessionRateUser({
    payload,
}: ReturnType<typeof postFocusSessionUserRatingSaga>) {
    try {
        if (
            !payload.rating.rated_by ||
            !payload.rating.rated_user ||
            !payload.rating.rating ||
            !payload.sessionId
        ) {
            return;
        }

        const {data}: {data: IRatings} =
            yield SessionsService.postRateFocusSession({
                sessionId: payload.sessionId,
                ratedSession: {
                    ...payload.rating,
                },
            });

        if (data) {
            const {data: session} = yield SessionsService.getFocusSession({
                focusSessionId: payload.sessionId,
            });
            if (session) {
                yield put(setFocusSessionAction({session: session}));
                payload.callbackOnSuccess && payload.callbackOnSuccess();
            }
        }
    } catch (error: any) {
        console.log({error});
        payload.callbackOnError && payload.callbackOnError({message: error});
        Sentry.captureException(error);
    }
}

function* handlePostAddUserToFavouritesList({
    payload,
}: ReturnType<typeof postAddUserToFavouritesListSaga>) {
    try {
        if (!payload.userId) {
            throw new Error('Parameter missing.');
        }
        const {data}: {data: IUserFavourites} =
            yield SessionsService.addUserToFavouritesList({
                userId: payload.userId,
            });
        if (data) {
            payload.callbackOnSuccess && payload.callbackOnSuccess();
        }
    } catch (error: any) {
        console.log({error});
        payload.callbackOnError && payload.callbackOnError(error);
        Sentry.captureException(error);
    }
}

function* handlePostFocusSessionReportUser({
    payload,
}: ReturnType<typeof postFocusSessionReportUserSaga>) {
    try {
        const selectAuthUserIdValue: number = yield select(selectAuthUserId);
        const selectFocusSessionData: ISession = yield select(
            selectFocusSession,
        );
        if (
            !payload.sessionId ||
            !payload.data.info ||
            !payload.data.subject ||
            !selectFocusSessionData ||
            !selectAuthUserIdValue
        ) {
            throw new Error('Parameter missing.');
        }
        const reportedUserId =
            selectFocusSessionData &&
            selectFocusSessionData?.participants &&
            selectFocusSessionData?.participants?.length > 1 &&
            // @ts-ignore
            selectFocusSessionData?.participants.find(
                participant => participant?.id !== selectAuthUserIdValue,
            ).id;
        const {data} = yield SessionsService.postReportFocusSessionUser({
            focusSessionId: payload.sessionId,
            data: {
                reported_user: reportedUserId as number,
                reported_by: selectAuthUserIdValue,
                ...payload.data,
            },
        });

        if (data) {
            payload.callbackOnSuccess && payload.callbackOnSuccess();
        }
    } catch (error: any) {
        console.log({error});
        payload.callbackOnError && payload.callbackOnError({message: error});
        Sentry.captureException(error);
    }
}

/**
 * @param {ReturnType<typeof joinFocusSessionSaga>} {
 *     payload,
 * }
 */
function* handleJoinFocusSessions({
    payload,
}: ReturnType<typeof joinFocusSessionSaga>) {
    try {
        const {data} = yield SessionsService.joinFocusSessions({
            sessionId: payload.sessionId,
            title: payload.title,
        });

        if (data) {
            const selectCalendarSessions: ISession[] = yield select(
                selectCalendarFocusSessions,
            );

            const authUserFavourites: IUserFavourites[] = yield select(
                selectAuthUserFavourites,
            );
            const authUserId: number = yield select(selectAuthUserId);

            const selectFocusSessionsData: IFocusSessions = yield select(
                selectFocusSessions,
            );

            const findItem = selectCalendarSessions?.filter(
                el => el.id !== data.id,
            );

            const selectFocusSessionsFavouritesData: IFocusSessions =
                yield select(selectFocusSessionsFavourites);

            const findItemFavourites =
                selectFocusSessionsFavouritesData?.results?.filter(
                    el => el.id !== data.id,
                );

            const isSessionsOfAnFavouritePartner = authUserFavourites?.find(
                fav =>
                    fav.user?.id ===
                    data?.participants?.find(
                        (p: IFocusSessionParticipant) => p?.id !== authUserId,
                    )?.id,
            );

            if (selectFocusSessionsData?.results?.length >= 0) {
                yield put(
                    setFocusSessionsMeAction({
                        reset: true,
                        sessions: {
                            results: selectFocusSessionsData.results.concat([
                                data,
                            ]),
                        },
                    }),
                );
            }

            if (
                findItemFavourites?.length >= 0 &&
                isSessionsOfAnFavouritePartner
            ) {
                yield put(
                    setFocusSessionsFavouritesAction({
                        reset: true,
                        sessions: {
                            results: findItemFavourites.concat([data]),
                        },
                    }),
                );
            }

            if (findItem?.length >= 0) {
                yield put(
                    setFocusSessionsByDateAction({
                        calendar: findItem.concat([data]),
                    }),
                );
            }

            const {data: userData} = yield AuthService.handleGetAuthUser();
            yield put(
                setAuthRemainingFreeSessionsAction(
                    userData.remaining_free_sessions_this_week,
                ),
            );

            payload.callbackOnSuccess && payload.callbackOnSuccess({data});
        }
    } catch (error: any) {
        console.log({error});
        const quotaExceeded: string = yield i18n.t('common.quotaExceeded');
        payload.callbackOnError &&
            payload.callbackOnError({
                message:
                    error?.response?.data?.error || error?.response.data.detail,
                title: error?.response.data.detail ? quotaExceeded : '',
                type: error?.response.data.detail ? 'warn' : 'error',
            });
        Sentry.captureException(error);
    }
}

/**
 * @param {ReturnType<typeof postFocusSessionLeaveSaga>} {
 *     payload,
 * }
 */
function* handlePostFocusSessionLeave({
    payload,
}: ReturnType<typeof postFocusSessionLeaveSaga>) {
    try {
        if (!payload.sessionId) {
            throw new Error('Param missing.');
        }
        const upcomingSession: IFocusSessions = yield select(
            selectFocusSessions,
        );
        const selectCalendarSessions: ISession[] = yield select(
            selectCalendarFocusSessions,
        );

        yield SessionsService.postLeaveFocusSession({
            focusSessionId: payload.sessionId,
            deleteType: payload.deleteType,
            leaveMessage: payload?.message,
        });
        const editingSession: ISession = yield select(selectFocusSession);
        const authUserId: number = yield select(selectAuthUserId);
        let itemRemoved: ISession | undefined = undefined;
        const findDeletedSession = upcomingSession.results.filter(
            (session: ISession) => {
                return session.id !== payload.sessionId;
            },
        );
        const findCalendarItem = selectCalendarSessions?.filter(
            el => el.id !== payload.sessionId,
        );

        if (editingSession?.participants?.length === 2) {
            const sessionPartner: IFocusSessionParticipant | undefined =
                editingSession?.participants?.find(p => p.id !== authUserId);
            if (sessionPartner)
                itemRemoved = {
                    ...editingSession,
                    participants: [sessionPartner],
                };
        }

        yield put(
            setFocusSessionsMeAction({
                sessions: {
                    results: findDeletedSession,
                },
                reset: true,
            }),
        );

        yield put(
            setFocusSessionsByDateAction({
                calendar: !itemRemoved
                    ? findCalendarItem
                    : findCalendarItem.concat([itemRemoved]),
            }),
        );

        const {data: userData} = yield AuthService.handleGetAuthUser();

        yield put(
            setAuthRemainingFreeSessionsAction(
                userData.remaining_free_sessions_this_week,
            ),
        );

        payload.callbackOnSuccess && payload.callbackOnSuccess();
    } catch (error: any) {
        const e: string = yield i18n.t('common.anErrorHasOccurred');
        console.log({error});
        payload.callbackOnError({
            message: error?.response?.data?.detail ?? e,
        });
        Sentry.captureException(error);
    }
}

/**
 * @param {ReturnType<typeof deleteFocusSessionSaga>} {
 *     payload,
 * }
 */
function* handleDeleteFocusSession({
    payload,
}: ReturnType<typeof deleteFocusSessionSaga>) {
    try {
        const editingSession: ISession = yield select(selectEditingSession);

        yield SessionsService.deleteFocusSession({
            id: editingSession.id,
        });

        const upcomingSession: IFocusSessions = yield select(
            selectFocusSessions,
        );
        const selectCalendarSessions: ISession[] = yield select(
            selectCalendarFocusSessions,
        );

        const findDeletedSession = upcomingSession.results.filter(
            (session: ISession) => session.id !== editingSession.id,
        );
        const findCalendarItem = selectCalendarSessions?.filter(
            el => el.id !== editingSession.id,
        );

        yield put(
            setFocusSessionsMeAction({
                sessions: {
                    results: findDeletedSession,
                },
                reset: true,
            }),
        );

        yield put(
            setFocusSessionsByDateAction({
                calendar: findCalendarItem,
            }),
        );

        payload.callbackOnSuccess && payload.callbackOnSuccess();
    } catch (error: any) {
        const e: string = yield i18n.t('common.anErrorHasOccurred');
        payload.callbackOnError &&
            payload.callbackOnError({
                message:
                    error?.response?.data?.error ||
                    error?.response?.data?.detail ||
                    e,
            });
        console.log({error});
        Sentry.captureException(error);
    }
}

function* handleMyFocusSessionByDate({
    payload,
}: ReturnType<typeof getMyFocusSessionsByDateActionSaga>) {
    try {
        const {data}: AxiosResponse<ISession[]> =
            yield SessionsService.getMyFocusSessionsByDate(payload.date);

        if (data) {
            yield put(setMyFocusSessionsByDateAction({mySessionsByDate: data}));
        }

        payload.callbackOnSuccess && payload.callbackOnSuccess();
    } catch (error: any) {
        console.log({handleMyFocusSessionByDate: error});
        payload.callbackOnError && payload.callbackOnError();
        Sentry.captureException(error);
    }
}

function* handleGetFocusSessionsByDateRange({
    payload,
}: ReturnType<typeof getFocusSessionsByDateRangeSaga>) {
    try {
        const {data}: AxiosResponse<ISession[]> =
            yield SessionsService.getFocusSessionsByDateRange({
                startDate: payload.startDate,
                endDate: payload.endDate,
                filter:
                    payload.filterBy === 'Favourites'
                        ? 'from_favourites'
                        : payload.filterBy === 'My Sessions'
                        ? 'my_sessions'
                        : undefined,
                duration: payload.duration,
                groups: payload.groups,
            });

        if (data) {
            if (payload.calendar === 'others') {
                yield put(
                    setFocusSessionsByDateAction({
                        calendar: data,
                    }),
                );
            } else {
                yield put(
                    setMyFocusSessionsByDateAction({mySessionsByDate: data}),
                );
            }
        }
        payload.callbackOnSuccess && payload.callbackOnSuccess();
    } catch (error) {
        console.log({handleGetFocusSessionsByDateRange: error});
        payload.callbackOnError && payload.callbackOnError();
        Sentry.captureException(error);
    }
}

// * EVENTS FROM WEBSOCKET

function* handleWSFocusSessionUpdated({
    payload,
}: ReturnType<typeof wsFocusSessionUpdatedAction>) {
    try {
        if (payload.session.id) {
            const authUserId: IUser = yield select(selectAuthUserId);
            const selectCalendarSessions: ISession[] = yield select(
                selectCalendarFocusSessions,
            );
            const mySessions: IFocusSessions = yield select(
                selectFocusSessions,
            );
            const filterCalendarSessions = selectCalendarSessions?.filter(
                el => el.id !== payload.session.id,
            );

            const authUserParticipant = payload.session.participants.find(
                part => part.id === authUserId,
            )?.id;

            if (authUserParticipant) {
                yield put(
                    setFocusSessionAction2({
                        session: payload.session,
                    }),
                );
            } else {
                const filterMySessions = mySessions?.results?.filter(
                    el => el.id !== payload.session.id,
                );

                if (filterMySessions?.length >= 0) {
                    yield put(
                        setFocusSessionsMeAction({
                            sessions: {
                                results: filterMySessions,
                            },
                            reset: true,
                        }),
                    );
                }
            }

            const calendarSessions =
                selectCalendarSessions?.length > 0
                    ? selectCalendarSessions
                    : [];
            const calendarSession = calendarSessions?.findIndex(
                el => el.id === payload.session.id,
            );

            if (
                calendarSession !== -1 &&
                typeof calendarSession !== 'undefined' &&
                calendarSessions?.length > 0
            ) {
                yield put(
                    setCalendarFocusSessionAction({
                        session: payload.session,
                    }),
                );
            } else if (
                payload?.session?.participants?.length >= 1 ||
                authUserParticipant
            ) {
                yield put(
                    setFocusSessionsByDateAction({
                        calendar:
                            filterCalendarSessions?.length > 0
                                ? filterCalendarSessions.concat([
                                      payload.session,
                                  ])
                                : [payload.session],
                    }),
                );
            }
        }
    } catch (error) {
        console.log({error});
        Sentry.captureException(error);
    }
}

function* handleWSFocusSessionDeleted({
    payload,
}: ReturnType<typeof wsFocusSessionDeletedAction>) {
    try {
        if (payload.session.id) {
            const selectCalendarSessions: ISession[] = yield select(
                selectCalendarFocusSessions,
            );
            const mySessions: IFocusSessions = yield select(
                selectFocusSessions,
            );

            const filterCalendarSessions = selectCalendarSessions?.filter(
                el => el.id !== payload.session.id,
            );

            const filterMySessions = mySessions?.results?.filter(
                el => el.id !== payload.session.id,
            );

            if (filterMySessions?.length >= 0) {
                yield put(
                    setFocusSessionsMeAction({
                        sessions: {
                            results: filterMySessions,
                        },
                        reset: true,
                    }),
                );
            }

            if (filterCalendarSessions?.length >= 0) {
                yield put(
                    setFocusSessionsByDateAction({
                        calendar: filterCalendarSessions,
                    }),
                );
            }
        }
    } catch (error) {
        console.log({error});
        Sentry.captureException(error);
    }
}

export function* FocusSessionsSagas(): Generator<unknown, any, undefined> {
    return yield all([
        takeLatest(
            EFocusSessionsTypes.GET_FOCUS_SESSIONS_MY_FOCUS_SAGA,
            handleFocusSessionsMyFocus,
        ),
        takeLatest(
            EFocusSessionsTypes.GET_FOCUS_SESSIONS_STATS_SAGA,
            handleFocusSessionsStats,
        ),
        takeLatest(
            EFocusSessionsTypes.GET_FOCUS_SESSIONS_LEADERBOARD_SAGA,
            handleGetFocusSessionsLeaderBoard,
        ),
        takeLatest(
            EFocusSessionsTypes.GET_FOCUS_SESSIONS_ME_SAGA,
            handleFocusSessionsMe,
        ),
        takeLatest(
            EFocusSessionsTypes.GET_FOCUS_SESSIONS_BY_DATE_SAGA,
            handleFocusSessionsByDate,
        ),
        takeLatest(
            EFocusSessionsTypes.PATCH_FOCUS_SESSIONS_MY_FOCUS_SAGA,
            handlePatchMyFocusSessions,
        ),
        takeLatest(
            EFocusSessionsTypes.POST_BOOK_FOCUS_SESSION_SAGA,
            handleBookFocusSession,
        ),
        takeLatest(
            EFocusSessionsTypes.PATCH_BOOKED_FOCUS_SESSION_SAGA,
            handlePatchBookedFocusSession,
        ),
        takeLatest(
            EFocusSessionsTypes.DELETE_FOCUS_SESSION_SAGA,
            handleDeleteFocusSession,
        ),
        takeLatest(
            EFocusSessionsTypes.GET_FOCUS_SESSIONS_ME_PAST_SAGA,
            handleFocusSessionsMePast,
        ),
        takeLatest(
            EFocusSessionsTypes.JOIN_FOCUS_SESSION_SAGA,
            handleJoinFocusSessions,
        ),
        takeLatest(
            EFocusSessionsTypes.GET_FOCUS_SESSION_SAGA,
            handleFocusSession,
        ),
        takeLatest(
            EFocusSessionsTypes.POST_FOCUS_SESSION_RATE_USER_SAGA,
            handleFocusSessionRateUser,
        ),
        takeLatest(
            EFocusSessionsTypes.POST_ADD_USER_TO_FAVOURITE_LIST_SAGA,
            handlePostAddUserToFavouritesList,
        ),
        takeLatest(
            EFocusSessionsTypes.POST_FOCUS_SESSION_REPORT_USER_SAGA,
            handlePostFocusSessionReportUser,
        ),
        takeLatest(
            EFocusSessionsTypes.POST_FOCUS_SESSION_LEAVE_SAGA,
            handlePostFocusSessionLeave,
        ),
        takeLatest(
            EFocusSessionsTypes.GET_ME_FOCUS_SESSIONS_BY_DATE_SAGA,
            handleMyFocusSessionByDate,
        ),
        takeLatest(
            EFocusSessionsTypes.GET_FOCUS_SESSION_BY_DATE_RANGE_SAGA,
            handleGetFocusSessionsByDateRange,
        ),
        takeLatest(
            EFocusSessionsTypes.WS_FOCUS_SESSION_UPDATED_EVENT_SAGA_ACTION,
            handleWSFocusSessionUpdated,
        ),
        takeLatest(
            EFocusSessionsTypes.WS_FOCUS_SESSION_DELETED_EVENT_SAGA_ACTION,
            handleWSFocusSessionDeleted,
        ),
        takeLatest(
            EFocusSessionsTypes.GET_FOCUS_SESSIONS_FAVOURITES_SAGA_ACTION,
            handleFocusSessionsFavourites,
        ),
    ]);
}
