import {useCallback, useState, memo, useEffect} from 'react';
import {
    Box,
    Typography,
    Avatar,
    Button,
    TextField,
    CircularProgress,
    useTheme,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {createSelector} from 'reselect';
import moment from 'moment';

import {ReusableModalWindow} from 'components';
import styles from './styles.module.scss';
import {
    useAppSelector,
    selectCalendarFocusSessions,
    selectAuthUserTimezone,
    selectAuthUserId,
    selectAuthUserDatetimePreference,
    useAppDispatch,
    joinFocusSessionSaga,
    showToastAction,
    openFocusSessionModalAction,
    selectAuthUserProfileShowNewSessionModal,
    setShowNewSessionModal,
    selectAuthUserTourComplete,
} from 'store';
import {newDateWithTimezone} from 'utils';
import {ISession} from 'models';

const stateSelectorHandle = createSelector(
    selectCalendarFocusSessions,
    selectAuthUserTimezone,
    selectAuthUserId,
    selectAuthUserDatetimePreference,
    selectAuthUserProfileShowNewSessionModal,
    selectAuthUserTourComplete,
    (
        calendarFocusSessions,
        authUserTimezone,
        authUserId,
        authUserDatetimePreference,
        showNewSessionModal,
        authUserTourComplete,
    ) => {
        return {
            calendarFocusSessions,
            authUserTimezone,
            authUserId,
            authUserDatetimePreference,
            showNewSessionModal,
            authUserTourComplete,
        };
    },
);

export const SuggestSession = memo(
    ({loading}: {loading: {initial: boolean; action: boolean}}) => {
        const {t} = useTranslation();
        const theme = useTheme();
        const stateSelector = useCallback(stateSelectorHandle, []);
        const {
            calendarFocusSessions,
            authUserTimezone,
            authUserId,
            authUserDatetimePreference,
            showNewSessionModal,
            authUserTourComplete,
        } = useAppSelector(stateSelector);
        const dispatch = useAppDispatch();
        const [participantSessionTitle, setParticipantSessionTitle] = useState<
            | {
                  title: string;
                  sessionId: number;
              }[]
            | []
        >([]);
        const [sessionTitleError, setSessionTitleError] = useState<
            | {
                  id: number;
                  error: string;
              }[]
            | []
        >([]);

        /**
         * Parse date to human date format
         * @param {Date} date
         * @return string
         */
        const humanDate = (date: Date) => {
            return moment(date).format(
                `${authUserDatetimePreference?.date} ${authUserDatetimePreference?.time}`,
            );
        };

        /**
         * Book Session
         * @param {{session: ISession}} {session}
         */
        const bookSession = ({session}: {session: ISession}) => {
            if (!session) return;

            if (
                !participantSessionTitle?.find(p => p.sessionId === session.id)
                    ?.title
            ) {
                return setSessionTitleError(prev =>
                    prev
                        .filter(p => p.id !== session.id)
                        .concat([
                            {
                                id: session.id,
                                error: t('home.pleaseTellUsMoreAboutYourGoal'),
                            },
                        ]),
                );
            }

            if (session.participants.length > 1) {
                return dispatch(
                    showToastAction({
                        title: t('home.calendar.thisSessionCannotBeBooked'),
                        subTitle: t(
                            'home.calendar.thereIsNoActionAvailableForThisSession',
                        ),
                        open: true,
                        vertical: 'top',
                        horizontal: 'center',
                        icon: 'error',
                    }),
                );
            }

            dispatch(
                joinFocusSessionSaga({
                    sessionId: session.id,
                    byDate: true,
                    title:
                        participantSessionTitle?.find(
                            p => p.sessionId === session.id,
                        )?.title || '',
                    callbackOnSuccess: () => {
                        setSessionTitleError(prev =>
                            prev.filter(p => p.id !== session.id),
                        );

                        dispatch(
                            showToastAction({
                                title: t(
                                    'home.calendar.sessionBookSuccessfully',
                                ),
                                subTitle: t(
                                    'home.calendar.yourSessionBookSuccessfully',
                                    {
                                        first_name:
                                            session?.participants[0]
                                                ?.first_name,
                                        last_name_initial: `${session?.participants[0]?.last_name_initial}.`,
                                    },
                                ),
                                open: true,
                                vertical: 'top',
                                horizontal: 'center',
                            }),
                        );
                    },
                    callbackOnError: ({message, title, type}) => {
                        dispatch(
                            showToastAction({
                                title: title
                                    ? title
                                    : t('common.anErrorHasOccurred'),
                                subTitle: message,
                                open: true,
                                vertical: 'top',
                                horizontal: 'center',
                                icon: type,
                            }),
                        );
                    },
                }),
            );
        };

        useEffect(() => {
            if (authUserId && calendarFocusSessions?.length) {
                const titles = calendarFocusSessions
                    ?.filter(
                        session =>
                            new Date(session.start_at).getTime() >
                                new Date().getTime() &&
                            session.participants.length > 1,
                    )
                    ?.map(e => ({
                        title:
                            authUserId &&
                            e?.title &&
                            e?.title[authUserId.toString()]
                                ? e?.title[authUserId.toString()]
                                : e?.title &&
                                  e?.title[e?.created_by?.toString()]
                                ? e?.title[e?.created_by?.toString()]
                                : e.participants?.length
                                ? e?.title[e.participants[0].id.toString()]
                                : '',
                        sessionId: e.id,
                    }));

                setParticipantSessionTitle(titles);
            }
        }, [authUserId, calendarFocusSessions]);

        if (loading.initial) return null;

        return (
            <ReusableModalWindow
                open={!!showNewSessionModal && !!authUserTourComplete}
                height={'auto'}
                width={517}
                setOpen={() => {
                    dispatch(setShowNewSessionModal(false));
                }}
                alignItems="flex-start">
                <Typography
                    className={styles.title}
                    sx={{
                        color:
                            theme.palette.mode === 'light'
                                ? 'rgba(0, 0, 0, 0.85)'
                                : '#fff7ea',
                    }}>
                    {t('home.weAreHappyToHaveYouHere')}
                </Typography>
                <Typography className={styles.subTitle}>
                    {t('home.letsStartYourJourneyWithUs')}
                </Typography>
                <br />
                {calendarFocusSessions && calendarFocusSessions?.length > 0 && (
                    <>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                            }}>
                            <Typography
                                sx={{mb: 1}}
                                className={styles.subTitle}>
                                {t('home.bookYourFirstSession')}
                            </Typography>
                            <Box className={styles.sessionWrapper}>
                                {loading.initial ? (
                                    <Box className={styles.loadingBox}>
                                        <CircularProgress size={20} />
                                    </Box>
                                ) : (
                                    calendarFocusSessions
                                        .filter(
                                            session =>
                                                new Date(
                                                    session.start_at,
                                                ).getTime() >
                                                new Date().getTime(),
                                        )
                                        .map((session, index) => {
                                            const {date: sessionStartDate} =
                                                newDateWithTimezone({
                                                    currentDate: new Date(
                                                        session.start_at as Date,
                                                    ),
                                                    timezone:
                                                        authUserTimezone as string,
                                                });

                                            return (
                                                <Box
                                                    key={`${session.id}-${index}`}
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        position: 'relative',
                                                        backgroundColor:
                                                            theme.palette
                                                                .mode === 'dark'
                                                                ? '#ff9a0033'
                                                                : '#f5f5f5',
                                                        border:
                                                            session
                                                                ?.participants
                                                                ?.length > 1
                                                                ? '2px solid #a8cf97'
                                                                : 'rgba(255, 154, 0, 0.2)',
                                                    }}
                                                    className={styles.session}>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'space-between',
                                                        }}>
                                                        <Box>
                                                            <Typography
                                                                sx={{
                                                                    color:
                                                                        theme
                                                                            .palette
                                                                            .mode ===
                                                                        'light'
                                                                            ? 'rgba(0, 0, 0, 0.85)'
                                                                            : '#fff7ea',
                                                                }}
                                                                className={
                                                                    styles.snackTitle
                                                                }>
                                                                {authUserId &&
                                                                session?.title &&
                                                                session
                                                                    ?.title?.[
                                                                    authUserId.toString()
                                                                ]
                                                                    ? session
                                                                          ?.title?.[
                                                                          authUserId.toString()
                                                                      ]
                                                                    : session?.title &&
                                                                      session
                                                                          ?.title?.[
                                                                          session?.created_by?.toString()
                                                                      ]
                                                                    ? session
                                                                          ?.title?.[
                                                                          session?.created_by?.toString()
                                                                      ]
                                                                    : session
                                                                          ?.participants
                                                                          ?.length
                                                                    ? session
                                                                          ?.title?.[
                                                                          session.participants?.[0]?.id?.toString()
                                                                      ]
                                                                    : ''}
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    color:
                                                                        theme
                                                                            .palette
                                                                            .mode ===
                                                                        'light'
                                                                            ? '#59617b'
                                                                            : '#a6a9aa',
                                                                }}
                                                                className={
                                                                    styles.sessionPeriod
                                                                }>
                                                                {`${humanDate(
                                                                    sessionStartDate,
                                                                )}
                                                            ${t('home.for')} ${
                                                                    session.duration.toString() ===
                                                                    '60'
                                                                        ? '1 h'
                                                                        : `${session.duration} m`
                                                                }`}
                                                            </Typography>
                                                            {session.participants.map(
                                                                (
                                                                    part,
                                                                    index,
                                                                ) => {
                                                                    return (
                                                                        <Box
                                                                            key={`${part.id}-${index}`}
                                                                            className={
                                                                                styles.participant
                                                                            }>
                                                                            <Avatar
                                                                                className={
                                                                                    styles.participantAvatar
                                                                                }
                                                                                src={
                                                                                    part.avatar ||
                                                                                    '/broken-img.jpg'
                                                                                }
                                                                            />
                                                                            <Typography
                                                                                sx={{
                                                                                    color:
                                                                                        theme
                                                                                            .palette
                                                                                            .mode ===
                                                                                        'light'
                                                                                            ? '#59617b'
                                                                                            : '#a6a9aa',
                                                                                }}
                                                                                className={
                                                                                    styles.participantFullName
                                                                                }>
                                                                                {
                                                                                    part.first_name
                                                                                }
                                                                                {
                                                                                    part.last_name_initial
                                                                                }

                                                                                .
                                                                            </Typography>
                                                                        </Box>
                                                                    );
                                                                },
                                                            )}
                                                        </Box>
                                                        <Button
                                                            variant={'outlined'}
                                                            onClick={() =>
                                                                bookSession({
                                                                    session,
                                                                })
                                                            }
                                                            disabled={
                                                                session
                                                                    ?.participants
                                                                    ?.length >=
                                                                2
                                                            }
                                                            className={
                                                                styles.bookSession
                                                            }>
                                                            {session
                                                                ?.participants
                                                                ?.length < 2
                                                                ? t('home.book')
                                                                : t(
                                                                      'home.booked',
                                                                  )}
                                                        </Button>
                                                    </Box>
                                                    <TextField
                                                        fullWidth
                                                        placeholder={t(
                                                            'home.calendar.whatIsYourGoal',
                                                        )}
                                                        inputProps={{
                                                            className:
                                                                styles.input,
                                                        }}
                                                        disabled={
                                                            session
                                                                ?.participants
                                                                ?.length > 1
                                                        }
                                                        error={
                                                            !!sessionTitleError.find(
                                                                s =>
                                                                    s.id ===
                                                                    session.id,
                                                            )
                                                        }
                                                        helperText={
                                                            sessionTitleError &&
                                                            sessionTitleError.length >
                                                                0
                                                                ? sessionTitleError?.find(
                                                                      s =>
                                                                          s.id ===
                                                                          session?.id,
                                                                  )?.error
                                                                : ''
                                                        }
                                                        sx={{
                                                            mt: 0.5,
                                                            color:
                                                                theme.palette
                                                                    .mode ===
                                                                'dark'
                                                                    ? '#FFFFFF'
                                                                    : '#000',
                                                            WebkitTextFillColor:
                                                                theme.palette
                                                                    .mode ===
                                                                'dark'
                                                                    ? '#FFFFFF'
                                                                    : '#000',
                                                        }}
                                                        value={
                                                            participantSessionTitle.find(
                                                                p =>
                                                                    p.sessionId ===
                                                                    session.id,
                                                            )?.title || ''
                                                        }
                                                        onChange={event => {
                                                            setParticipantSessionTitle(
                                                                prev => {
                                                                    return prev
                                                                        .filter(
                                                                            p =>
                                                                                p.sessionId !==
                                                                                session.id,
                                                                        )
                                                                        .concat(
                                                                            [
                                                                                {
                                                                                    title: event
                                                                                        .target
                                                                                        .value,
                                                                                    sessionId:
                                                                                        session.id,
                                                                                },
                                                                            ],
                                                                        );
                                                                },
                                                            );
                                                        }}
                                                        variant="standard"
                                                    />
                                                </Box>
                                            );
                                        })
                                )}
                            </Box>
                        </Box>
                        <Box className={styles.orWrapper}>
                            <Box className={styles.line} />
                            <Typography className={styles.or}>
                                {t('register.or')}
                            </Typography>
                            <Box className={styles.line} />
                        </Box>
                    </>
                )}
                <Button
                    variant={'contained'}
                    className={styles.newSession}
                    onClick={() => dispatch(openFocusSessionModalAction(true))}>
                    {t('home.newSession')}
                </Button>
            </ReusableModalWindow>
        );
    },
);
