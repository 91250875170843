import {TStore} from 'models/index';

export const selectAuthUser = (state: TStore) => state?.auth?.data;

export const selectAuthUserId = (state: TStore) => state?.auth?.data?.user?.id;

export const selectAuthUserToken = (state: TStore) => state?.auth?.data?.token;

export const selectAuthUserTokenExpiry = (state: TStore) =>
    state?.auth?.data?.expiry;

export const selectAuthUserTimezoneLastVerified = (state: TStore) =>
    state?.auth?.data?.user?.timezone_verified;

export const selectAuthUserTimezone = (state: TStore) =>
    state?.auth?.data?.user?.timezone;

export const selectAuthUserTourComplete = (state: TStore) =>
    state?.auth?.data?.user?.tour_complete;

export const selectAuthUserProfileComplete = (state: TStore) =>
    state?.auth?.data?.user?.profile_complete;
export const selectAuthUserProfileCompany = (state: TStore) =>
    state?.auth?.data?.user?.company_membership?.id;

export const selectAuthUserFirstName = (state: TStore) =>
    state?.auth?.data?.user?.first_name;

export const selectAuthUserAvatar = (state: TStore) =>
    state?.auth?.data?.user?.avatar;

export const selectAuthUserFullName = (state: TStore) =>
    state?.auth?.data?.user?.first_name +
    ' ' +
    state?.auth?.data?.user?.last_name;

export const selectAuthUserName = (state: TStore) => {
    return {
        firstName: state?.auth?.data?.user?.first_name,
        lastName: state?.auth?.data?.user?.last_name,
        lastNameInitial: state?.auth?.data?.user?.last_name_initial,
    };
};

export const selectAuthUserDatetimePreference = (state: TStore) => {
    return state.auth.data.user?.datetime_preference;
};

export const selectAuthUserProfileUrl = (state: TStore) =>
    state?.auth?.data?.user?.profile_url;

export const selectAuthUserGender = (state: TStore) =>
    state?.auth?.data?.user?.gender;

export const selectAuthUserFavourites = (state: TStore) =>
    state?.auth?.data?.favourites;

export const selectAuthUserRegisteredPassword = (state: TStore) =>
    state?.auth?.temp;

export const selectAuthUserEmail = (state: TStore) =>
    state?.auth?.data?.user?.email;

export const selectAuthUserTourCurrentStep = (state: TStore) =>
    state?.auth?.tourCurrentStep;

export const selectAuthUserProfileDarkMode = (state: TStore) =>
    state?.auth?.data?.user?.profile?.dark_mode;

export const selectAuthUserCompanyId = (state: TStore) =>
    state?.auth?.data?.user?.company_membership?.id;

export const selectAuthUserCompany = (state: TStore) =>
    state?.auth?.data?.user?.company_membership;

export const selectAuthUserCompanyAdmin = (state: TStore) =>
    state?.auth?.data?.user?.company_membership?.admin;

export const selectAuthUserCompanyAddress = (state: TStore) =>
    state?.auth?.data?.user?.company_membership?.address;

export const selectAuthUserSidebarOpen = (state: TStore) =>
    state?.auth?.data?.user?.profile?.sidebar_open;

export const selectAuthUserCompanyName = (state: TStore) =>
    state?.auth?.data?.user?.company_membership?.name;

export const selectAuthUserLanguageLanguage = (state: TStore) =>
    state?.auth?.data?.user?.profile?.language;

export const selectAuthUserMessage = (state: TStore) =>
    state?.auth?.data?.user?.message;

export const selectAuthUserProfileShowNewSessionModal = (state: TStore) =>
    state?.auth?.data?.user?.profile?.show_new_session_modal;

export const selectAuthUserSessionsCompleted = (state: TStore) =>
    state?.auth?.data?.user?.profile?.completed_sessions;

export const selectAuthUserIsPremium = (state: TStore) =>
    state?.auth?.data?.user?.is_premium;

export const selectAuthUserRemainingFreeSessions = (state: TStore) =>
    state?.auth?.data?.user?.remaining_free_sessions_this_week;
